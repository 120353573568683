<script setup lang="ts">
import { HomeBanners } from "~/types";
const prepareImgUrl = useImage();

const { t } = useT();

defineProps<{ banner: HomeBanners[number] }>();

const paymentsImg = ["visa", "mc", "apple"] as const;
const {
	public: { baseImageUrl }
} = useRuntimeConfig();

const images = [
	{
		width: 122,
		height: 103,
		alt: "coin"
	},
	{
		width: 59,
		height: 57,
		alt: "coin"
	},
	{
		width: 60,
		height: 61,
		alt: "coin"
	},
	{
		width: 69,
		height: 67,
		alt: "coin"
	},
	{
		width: 132,
		height: 110,
		alt: "coin"
	}
];
</script>

<template>
	<div class="banner-container">
		<ABanner
			:img-attrs="{
				alt: 'banner',
				src: `${baseImageUrl}${banner?.image2x}` || '',
				format: 'webp',
				width: 636,
				height: 435
			}"
			:sources="[
				{
					srcset: prepareImgUrl(`${baseImageUrl}${banner?.imageMobile2x}` || '', {
						format: 'webp',
						width: 638,
						height: 408
					}),
					width: 638,
					height: 408,
					media: '(max-width: 767px)'
				}
			]"
			:background="`url(${baseImageUrl}${banner?.backgroundImage2x})`"
			class="banner"
		>
			<div class="content">
				<div class="header">
					<AText :modifiers="['uppercase', 'nowrap']" as="h1" class="title" variant="taiz"> {{ banner?.title }} </AText>
					<div class="subtitle">
						<AText :modifiers="['uppercase', 'bold', 'nowrap']" variant="taipei">{{ banner?.smallTitle }}</AText>
						<AText class="text-caracas" variant="tuzla" :modifiers="['uppercase', 'bold', 'nowrap']">{{
							banner?.subTitle
						}}</AText>
					</div>
					<div class="holder">
						<AAnimationCoins>
							<div v-for="(item, index) in 5" :key="index" :class="`coin coin-${index + 1}`">
								<NuxtImg
									:width="images[index]?.width"
									:height="images[index]?.height"
									:alt="images[index]?.alt"
									:src="`/nuxt-img/banners/coins/coin-${index + 1}.webp`"
								/>
							</div>
						</AAnimationCoins>
					</div>
				</div>
				<div class="auth">
					<OHomeForm />
				</div>
				<AAnimationCoins>
					<div v-for="(imageNumber, index) in [1, 2, 3, 4, 5, 4, 1]" :key="index" :class="`coin coin-${index + 1}`">
						<NuxtImg
							:width="images[imageNumber - 1]?.width"
							:height="images[imageNumber - 1]?.height"
							:alt="images[imageNumber - 1]?.alt"
							:src="`/nuxt-img/banners/coins/coin-${imageNumber}.webp`"
						/>
					</div>
				</AAnimationCoins>
			</div>
			<div class="wheel-holder">
				<NuxtImg
					src="/nuxt-img/wheel-promo/circle@2x.png"
					format="webp"
					class="circle"
					width="725"
					height="724"
					loading="lazy"
					alt="circle"
				/>
				<NuxtImg
					src="/nuxt-img/wheel-promo/arrow.png"
					format="webp"
					class="arrow"
					width="88"
					height="120"
					loading="lazy"
					alt="arrow"
				/>
				<NuxtImg
					src="/nuxt-img/wheel-promo/wheel.png"
					format="webp"
					class="carousel"
					width="898"
					height="901"
					loading="lazy"
					alt="wheel"
				/>
				<NuxtImg
					src="/nuxt-img/wheel-promo/spin.png"
					format="webp"
					class="spin"
					width="103"
					height="103"
					loading="lazy"
					alt="spin"
				/>
				<NuxtImg
					src="/nuxt-img/wheel-banner/front-leaves.png"
					format="webp"
					class="leaves"
					width="268"
					height="408"
					loading="lazy"
					alt="front-leaves"
				/>
			</div>
		</ABanner>
		<div class="text-box">
			<AText :modifiers="['uppercase', 'bold', 'center']" as="div" variant="tiraspol" class="text">{{
				t("UNLIMITED WHEEL OF FORTUNE EVERY 24H!")
			}}</AText>
		</div>
		<div class="payments">
			<NuxtImg
				v-for="payment in paymentsImg"
				:key="`${payment}-key-payments`"
				:src="`/nuxt-img/payment-methods/${payment}.png`"
				format="webp"
				:alt="payment"
				loading="lazy"
			/>
		</div>
	</div>
</template>

<style scoped lang="scss">
section {
	--a-banner-default-height: auto;
	--a-banner-default-pictute-height: auto;
	--a-banner-default-border-radius: 0;
	--a-banner-default-image-height: 384px;

	@include media-breakpoint-up(md) {
		--a-banner-default-height: auto;
		--a-banner-default-image-height: auto;
	}

	@include media-breakpoint-up(lg) {
		--a-banner-default-border-radius: 16px 16px 0 0;
		--a-banner-default-image-radius: 16px 16px 0 0;
	}
}

.flex-column {
	display: flex;
	flex-direction: column;
}

.banner-container {
	position: relative;
	padding-bottom: 16px;

	@include media-breakpoint-down(lg) {
		padding: 0 16px 16px;
	}

	@include media-breakpoint-down(md) {
		padding: 0 0 8px 0;
	}

	&:deep(section) {
		min-height: var(--a-banner-default-height);

		@include media-breakpoint-down(md) {
			background: var(--craiova);
		}
	}

	&:deep(section > div) {
		position: relative;
	}

	&:deep(section > picture) {
		@include media-breakpoint-down(md) {
			left: 0;
			right: 0;
			transform: none;

			img {
				border-radius: 0;
				width: 100%;
			}
		}
	}

	.banner {
		background-repeat: no-repeat;
		background-size: cover;
		overflow: hidden;

		&.email-error {
			height: 700px;
		}
	}

	.content {
		@extend .flex-column;
		position: static;
		align-items: center;
		width: 100%;
		padding: gutter(7.5) gutter(2) gutter(3);

		@include media-breakpoint-up(md) {
			padding: gutter(4) 16.5% gutter(5);
			align-items: flex-start;
			gap: gutter(2);
		}

		@media (min-width: 1024px) and (max-width: 1279px) {
			padding: gutter(2) 6% gutter(2);
		}
	}

	.header {
		@extend .flex-column;
		justify-content: center;
		padding-bottom: gutter(8);
		position: relative;
		z-index: 1;

		@include media-breakpoint-up(md) {
			padding-bottom: 0;
			gap: gutter(0.5);
		}

		.holder {
			position: absolute;
			top: 50%;
			left: 50%;
			transform: translate(-50%, -50%);
			width: 360px;
			height: 300px;
			z-index: -1;

			@include media-breakpoint-up(md) {
				display: none;
			}

			&:before {
				content: "";
				position: absolute;
				top: 50%;
				left: 50%;
				transform: translate(-50%, -42%);
				width: 360px;
				height: 400px;
				background: url("/nuxt-img/banners/frame.webp") no-repeat 50% 50% / cover;
			}

			.coin-1 {
				left: 100%;
			}

			.coin-2 {
				left: 90%;
			}

			.coin-3 {
				left: 80%;
			}

			.coin-4 {
				left: 70%;
			}

			.coin-5 {
				left: 60%;
			}
		}
	}

	.title {
		line-height: 50px;

		@include media-breakpoint-up(lg) {
			font-size: 54px;
			line-height: 64px;
		}
	}

	.subtitle {
		@extend .flex-column;
		align-items: center;

		@include media-breakpoint-up(md) {
			flex-direction: row;
			gap: gutter(0.5);
		}

		@include media-breakpoint-up(lg) {
			> span {
				font-size: 38px;
				line-height: 50px;
			}
		}
	}

	.button {
		width: 100%;
		max-width: 648px;

		@include media-breakpoint-up(md) {
			width: 328px;
		}
	}
}

.socials {
	padding: 0;
	display: none;

	@include media-breakpoint-down(md) {
		display: flex;
	}
}

.auth {
	@extend .flex-column;
	gap: gutter(1);
	align-items: center;
	max-width: 624px;
	position: relative;
	z-index: 2;
}

.text-box {
	padding: gutter(1.5) gutter(2.5);
	position: relative;

	@include media-breakpoint-up(md) {
		border-radius: 0 0 16px 16px;
	}

	.text {
		line-height: 1;
		position: relative;
		text-shadow:
			0 2px 4px rgba(var(var(--corlu-rgb)), 0.06),
			0 4px 6px rgba(var(var(--corlu-rgb)), 0.1);
	}

	&:before {
		content: "";
		position: absolute;
		top: 0;
		left: 0;
		right: 0;
		bottom: 0;
		background: var(--gilbert);
		background-size: 300% 100%;
		animation: changeGradient 5s ease-in-out infinite;

		@include media-breakpoint-up(md) {
			border-radius: 0 0 16px 16px;
		}
	}
}

.payments {
	padding: gutter(1.5);
	display: none;
	justify-content: center;
	align-items: center;
	gap: gutter(3);
	background: var(--cannes);

	img {
		max-height: 21px;
	}

	@include media-breakpoint-down(md) {
		display: flex;
	}
}

.wheel-holder {
	position: absolute;
	width: 100%;
	max-width: 390px;
	height: auto;
	bottom: 0;
	rotate: -12deg;
	transform: translateY(27%);

	@include media-breakpoint-down(md) {
		display: none;
	}

	@include media-breakpoint-up(md) {
		right: -50px;
	}

	.carousel,
	.circle {
		width: 100%;
		height: auto;
	}

	.carousel {
		animation: rotate 3s ease-in-out infinite;
	}

	.spin,
	.circle,
	.arrow,
	.leaves {
		position: absolute;
	}

	.spin {
		position: absolute;
		z-index: 1;
		top: 50%;
		left: 50%;
		transform: translate(-50%, -50%);

		@include media-breakpoint-up(lg) {
			transform: translate(-50%, -50%) scale(1.1);
		}
	}

	.arrow {
		left: 50%;
		transform: translateX(-50%);
		width: 40px;
		top: -11px;
		z-index: 2;
		height: auto;

		@include media-breakpoint-up(lg) {
			width: 55px;
			top: -15px;
		}
	}

	.leaves {
		display: none;
		z-index: 3;
		right: -6%;
		bottom: -2%;
		width: 230px;
		height: auto;
		rotate: 22deg;
		animation: levitate 9s ease-in-out infinite;

		@include media-breakpoint-up(md) {
			display: block;
		}
	}
}

@keyframes changeGradient {
	0% {
		background-position: 0 0;
	}

	50% {
		background-position: 100% 0;
	}

	100% {
		background-position: 0 0;
	}
}

@keyframes rotate {
	0% {
		transform: rotate(-10deg);
	}

	50% {
		transform: rotate(10deg);
	}

	100% {
		transform: rotate(-10deg);
	}
}
</style>
